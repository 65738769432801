// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "containers/Navbars/DashboardNavbar";
import Footer from "containers/Footer";
import DataTable from "containers/Tables/DataTable";

// Data
import projectsTableData from "pages/dashboard/data/projectsTableData";
import dayjs from "dayjs";
import { useEffect, useState, useContext } from "react";
import axiosClient from "../../helpers/hooks/axiosClient";
import { useAtom } from "jotai";
import { facilityDataAtom, portfolioDataAtom, changedMaxBidCountAtom, readySubmitAtom } from "store/data";
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom'

import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc);
dayjs.extend(timezone);

function Dashboard() {
  const navigate = useNavigate();
  const { isAuthenticated, auth, setAuthCred, setPortfolioContext } = useContext(AuthContext);
  const [changedMaxBidCount, setChangedMaxBidCount] = useAtom(changedMaxBidCountAtom)
  const [readySubmit, setReadySubmit] = useAtom(readySubmitAtom);
  const [facilityData, setFacilityData] = useAtom(facilityDataAtom);
  const [portfolioData, setPortfolioData] = useAtom(portfolioDataAtom);
  const [isTargetRanking, setIsTargetRanking] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [newMaxBid, setNewMaxBid] = useState(0);
  const [submissionLogModalOpen, setSubmissionLogModalOpen] = useState(false);
  const [submissionLog, setSubmissionLog] = useState([])
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openPTSelect, setOpenPTSelect] = useState(false)
  const [portfolios, setPortfolios] = useState([])
  const [tagFilter, setTagFilter] = useState([]);

  const {
    columns: pColumns,
    rows: pRows,
    initialState: pInitialState,
    max_bid: maxBid,
    has_occupancy: hasOccupancy
  } = projectsTableData(facilityData, isTargetRanking);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/sign-in');
    }
    if (auth) {
      reloadData();
    }
  }, [isAuthenticated, auth])

  const reloadData = async () => {
    setLoading(true)

    if (auth?.role === 'global_admin') {
      const response = await axiosClient.get("/portfolio")
      const portfolios = response.data.result
      setPortfolios(portfolios)
      if (portfolios.length) {
        const portfolio = portfolios[0];
        setPortfolioData(portfolio);
        setPortfolioContext(portfolio)
        setIsTargetRanking(portfolio?.current_strategy === 'target_ranking')

        if (portfolio?.tags?.length > 0) {
          setTags(portfolio.tags)
        }

        axiosClient.get(`/facility?filter_tags=${JSON.stringify(tagFilter)}&portfolio_id=${portfolios[0].portfolio_id}`).then(result => {
          const dataRes = result.data.result;

          setFacilityData(dataRes);
          setLoading(false)
        })
      }
    } else {
      axiosClient.get("/portfolio/user").then(result => {
        setPortfolioData(result.data.result);
        setPortfolioContext(result.data.result)
        setIsTargetRanking(result.data.result?.current_strategy === 'target_ranking')

        const portfolio = result.data.result
        if (portfolio?.tags?.length > 0) {
          setTags(portfolio?.tags)
        }
      })

      axiosClient.get(`/facility?filter_tags=${JSON.stringify(tagFilter)}`).then(result => {
        const dataRes = result.data.result;
        setFacilityData(dataRes);
        setLoading(false)
      })
    }
  }

  const handlePortfolioMaxBid = () => {
    if (auth?.role === 'global_admin') return;
    setNewMaxBid(maxBid);
    setModalOpen(true);
  };

  const handleFacilityMaxBid = () => {
    if (auth?.role === 'global_admin') return;
    updateStrategy("facility_max_bid");
  };

  const handleTargetRanking = () => {
    if (auth?.role === 'global_admin') return;
    updateStrategy("target_ranking");
  }

  const handleClose = () => {
    setModalOpen(false);
    setSubmissionLogModalOpen(false);
  };

  const handleReloadData = async () => {
    navigate('/loading');
  }

  const updateStrategy = (value) => {
    axiosClient.put("/portfolio", { current_strategy: value }).then(result => {
      setPortfolioData({
        ...portfolioData,
        current_strategy: value
      })
      setIsTargetRanking(value === 'target_ranking')
    })
  }
  const handleApply = () => {
    const result = Number.parseFloat(newMaxBid / 0.05).toFixed(2);
    const regex = /^[0-9]+(\.00)?$/;
    if (regex.test(result)) {
      axiosClient.post("/facility/update_all", { max_bid: newMaxBid }).then(result => {
        console.log("updated max bid across all facilities")
        setReadySubmit(false);
        setChangedMaxBidCount((changedMaxBidCount || 0) + 1);
        reloadData()
      })
      updateStrategy("portfolio_max_bid");
      setModalOpen(false);
      setError(false)
    } else {
      setError(true)
    }
  };

  const setUserRecords = (records) => {
    axiosClient.put(`/users/${auth._id}`, { records }).then((response) => {
      let { data } = response
      setAuthCred(data.result)
    })
  }

  const showBidsHistory = () => {
    axiosClient.get("/bid/bids_history").then(result => {
      setSubmissionLog(result.data.result)
      setSubmissionLogModalOpen(true)
    })
  }

  const handlePortfolioChanged = (pt) => {
    setPortfolioData({ ...pt })
    setOpenPTSelect(false)
    setLoading(true)
    axiosClient.get(`/facility?portfolio_id=${pt.portfolio_id}`).then(result => {
      setFacilityData(result.data.result);
      setLoading(false)
    })
  }

  const handleTagFilter = (tags) => {
    setTagFilter(tags)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        accountName={portfolioData ? portfolioData.portfolio_name : ''}
        onClick={() => setOpenPTSelect(!openPTSelect)}
      >
        {auth?.role === 'global_admin' && openPTSelect && (
          <div
            style={{
              position: 'absolute',
              top: 40,
              left: 0,
              minWidth: 240,
              backgroundColor: '#fff',
              borderRadius: 9,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: '#ddd',
              padding: 10,
              display: 'flex',
              flexDirection: 'column',
              fontSize: '.8em',
              maxHeight: 400,
              overflowY: 'scroll',
              boxShadow: '5px 10px 10px rgba(0,0,0,0.1), -5px -5px 10px rgba(255,255,255,0.1)',
            }}
          >
            {portfolios.map((pt) => (
              <Box
                sx={{
                  cursor: 'pointer',
                  paddingX: 3,
                  paddingY: 1,
                  borderRadius: 2,
                  '&:hover': { backgroundColor: '#eee' },
                }}
                onClick={() => handlePortfolioChanged(pt)}
              >
                {pt.portfolio_name}
              </Box>
            ))}
          </div>
        )}
      </DashboardNavbar>

      <MDBox pt={2} pb={2} onClick={() => setOpenPTSelect(false)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={8} sx={(theme) => ({
                    [theme.breakpoints.only("xs")]: {
                      display: 'none',
                    },
                    [theme.breakpoints.only("sm")]: {
                      display: 'none',
                    }
                  })}>
                    <Box
                      mx={2}
                      px={3}
                      py={1}
                      sx={{
                        border: '1px solid #7b809a',
                        borderRadius: '10px',
                        display: 'flex',
                        width: 'fit-content',
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h6"
                        py={2}
                        style={{ color: 'grey', textTransform: 'uppercase', fontSize: '12px' }}
                      >
                        Strategy
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-between',
                          gap: 2,
                          paddingLeft: 4
                        }}
                      >
                        <MDButton
                          variant={
                            portfolioData?.current_strategy === 'portfolio_max_bid'
                              ? 'contained'
                              : 'outlined'
                          }
                          color={
                            portfolioData?.current_strategy === 'portfolio_max_bid'
                              ? 'error'
                              : 'dark'
                          }
                          onClick={handlePortfolioMaxBid}
                        >
                          Portfolio Max Bid
                        </MDButton>
                        <MDButton
                          variant={
                            portfolioData?.current_strategy === 'facility_max_bid'
                              ? 'contained'
                              : 'outlined'
                          }
                          color={
                            portfolioData?.current_strategy === 'facility_max_bid' ? 'error' : 'dark'
                          }
                          onClick={handleFacilityMaxBid}
                        >
                          Facility Max Bid
                        </MDButton>
                        <MDButton
                          variant={
                            portfolioData?.current_strategy === 'target_ranking'
                              ? 'contained'
                              : 'outlined'
                          }
                          color={
                            portfolioData?.current_strategy === 'target_ranking' ? 'error' : 'dark'
                          }
                          onClick={handleTargetRanking}
                        >
                          Target Ranking
                        </MDButton>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDBox display="flex" justifyContent="space-between">
                      <MDBox sx={(theme) => ({
                        [theme.breakpoints.only("xs")]: {
                          ml: 4,
                        }
                      })}>
                        <Typography
                          variant="h6"
                          component="h6"
                        
                          align="right"
                          sx={(theme) => ({
                            [theme.breakpoints.only("xs")]: {
                              fontSize: 12,
                              mt: 1,
                            },
                            [theme.breakpoints.only("sm")]: {
                              padding: 2,
                              fontSize: 12,
                              mt: 1,
                            },
                            mr: 4,
                          })}
                          style={{ color: 'grey' }}
                        >
                          {portfolioData?.last_optimized
                            ? `Last Optimized: ${dayjs.utc(portfolioData.last_optimized).local().format(
                              'MM/DD/YYYY, h:mm A'
                            )}`
                            : ''}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="h6"
                          align="right"
                          sx={(theme) => ({
                            [theme.breakpoints.only("xs")]: {
                              fontSize: 12,
                              mt: 1,
                            },
                            [theme.breakpoints.only("sm")]: {
                              padding: 2,
                              fontSize: 12,
                              mt: 1,
                            },
                            mr: 4,
                          })}
                          style={{ color: 'grey', cursor: 'pointer' }}
                          onClick={showBidsHistory}
                        >
                          {portfolioData?.last_submission
                            ? `Last Bids Update: ${dayjs.utc(portfolioData.last_submission).local().format(
                              'MM/DD/YYYY, h:mm A'
                            )}`
                            : ''}
                        </Typography>
                      </MDBox>
                      <MDBox p={2} sx={(theme) => ({
                        [theme.breakpoints.only("xs")]: {
                          padding: '0 5px',
                        }
                      })}
                      >
                        {auth?.role !== 'global_admin' && (
                          <MDButton
                            variant="contained"
                            color="warning"
                            sx={(theme) => ({
                              [theme.breakpoints.only("xs")]: {
                                fontSize: 10,
                                padding: 1,
                                marginRight: 1,
                                width: '100%'
                              }
                            })}
                            onClick={handleReloadData}
                          >
                            Reload Data
                          </MDButton>
                        )}
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox p={{ xs: 0, sm: 0, md: 0, lg: 4}}>
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '25vh',
                      width: '100%',
                      height: '50vh',
                      fontSize: 16,
                      color: '#555',
                    }}
                  >
                    Loading ...
                  </div>
                ) : auth ? (
                  <DataTable
                    tags={tags}
                    tagFilterDefault={tagFilter}
                    emitTagFilter={(tags) => handleTagFilter(tags)}
                    isTargetRanking={isTargetRanking}
                    table={{ columns: pColumns ?? [], rows: pRows ?? [], initialState: pInitialState ?? {} }}
                    isSorted={true}
                    entriesPerPage={{ defaultValue: auth.records, entries: [10, 20, 50, 100] }}
                    onEntriesChange={setUserRecords}
                    showTotalEntries={true}
                    hasOccupancy={hasOccupancy}
                    noEndBorder
                    isStickyHeader
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      height: '70vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    No results! Please try another search or select a new tag.
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle textAlign="center">Apply New Max Bid to Entire Portfolio</DialogTitle>
        {error && (
          <Typography variant="h6" component="h6" align="center" style={{ color: 'red' }}>
            Your bid must be in an increment of 0.05. Please try again.
          </Typography>
        )}
        <DialogContent>
          <FormControl>
            <div style={{ textAlign: 'center' }}>
              <input
                autoFocus
                label="New Max Bid"
                type="number"
                step="0.05"
                style={{ fontSize: 18, padding: 12, maxWidth: 180, marginBottom: 20 }}
                value={newMaxBid}
                onChange={(e) => setNewMaxBid(e.target.value)}
              />
            </div>
            <DialogContentText>
              You may still edit individual facility bids by clicking the Update Max Bid button for
              that facility.
            </DialogContentText>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
          <MDButton variant="outlined" color="error" onClick={handleApply}>
            Apply to all
          </MDButton>
          <MDButton
            variant="contained"
            style={{ color: 'white', background: 'grey' }}
            onClick={handleClose}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={submissionLogModalOpen} onClose={handleClose}>
        <DialogTitle textAlign="center">Submissions Log</DialogTitle>
        <DialogContent>
          <div style={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader>
                <TableHead style={{ display: 'table-header-group' }}>
                  <TableRow>
                    <TableCell>Submission Date</TableCell>
                    <TableCell>Number of Facilities</TableCell>
                    <TableCell>Average Bid</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submissionLog.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {dayjs(log.submission_datetime).format('MM/DD/YYYY, h:mm A')}
                      </TableCell>
                      <TableCell align="center">{log.number_of_facilities}</TableCell>
                      <TableCell align="center">{log.average_bid?.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
          <MDButton
            variant="contained"
            style={{ color: 'white', background: 'grey' }}
            onClick={handleClose}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  )
}

export default Dashboard;
